<!--
 * @Description: 图片上传组件
 * @Author: Neo
 * @Date: 2019-12-11
 * @LastEditTime: 2021-11-17
 * @LastEditors: Neo
-->
<template>
  <div class="uploadWrapper">
    <vuedraggable
      class="vue-draggable"
      :class="{ single: isSingle, maxHidden: isMaxHidden }"
      v-model="imgList"
      tag="ul"
      draggable=".draggable-item"
      @start="onDragStart"
      @end="onDragEnd"
    >
      <!-- 拖拽元素 -->
      <li
        v-for="(item, index) in imgList"
        :key="item + index"
        class="draggable-item"
        :style="{ width: width + 'px', height: height + 'px' }"
      >
        <el-image :src="item" :preview-src-list="[item]"></el-image>
        <div class="shadow" @click="onRemoveHandler(index)">
          <i class="el-icon-delete"></i>
        </div>
      </li>
      <!-- 上传按钮 -->
      <el-upload
        slot="footer"
        ref="uploadRef"
        class="uploadBox"
        :style="{ width: width + 'px', height: height + 'px' }"
        action="https://back.bjca.xyz/diary/file/upload"
        :headers="headers"
        accept=".jpg,.jpeg,.png,.gif"
        :show-file-list="false"
        :multiple="!isSingle"
        :limit="limit"
        :before-upload="beforeUpload"
        :on-success="onSuccessUpload"
        :on-exceed="onExceed"
      >
        <i class="el-icon-plus uploadIcon">
          <span class="uploading" v-show="isUploading">正在上传...</span>
          <span
            v-if="!isUploading && limit && limit!==99 && !isSingle"
            class="limitTxt"
          >最多{{ limit }}张</span>
        </i>
      </el-upload>
    </vuedraggable>
  </div>
</template>

<script>
import { Message } from 'view-design'
import vuedraggable from 'vuedraggable' // 一款vue拖拽插件
import lrz from 'lrz' // 一款图片压缩插件
import utils from './utils'

export default {
    name: 'ImgUpload',

    props: {
    // 图片数据(图片url组成的数组) 通过v-model传递
        value: {
            type: Array,
            default() {
                return []
            },
        },
        // 限制上传的图片数量
        limit: {
            type: Number,
            default: 9,
        },
        // 限制上传图片的文件大小(kb)
        size: {
            type: Number,
            default: 4096,
        },
        // 是否是单图上传(单图上传就是已传图片和上传按钮重叠)
        isSingle: {
            type: Boolean,
            default: false,
        },
        // 是否使用图片压缩
        useCompress: {
            type: Boolean,
            default: false,
        },
        // 图片显示的宽度(px)
        width: {
            type: Number,
            default: 100,
        },
        // 图片显示的高度(px)
        height: {
            type: Number,
            default: 100,
        },
    },

    data() {
        return {
            headers: {
                // 从本地存储获取 token
                authorization: localStorage.getItem('token'),
            },
            isUploading: false, // 正在上传状态
            isFirstMount: true, // 控制防止重复回显
        }
    },

    computed: {
    // 图片数组数据
        imgList: {
            get() {
                return this.value
            },
            set(val) {
                if (val.length < this.imgList.length) {
                    // 判断是删除图片时同步el-upload数据
                    this.syncElUpload(val)
                }
                // 同步v-model
                this.$emit('input', val)
            },
        },
        // 控制达到最大限制时隐藏上传按钮
        isMaxHidden() {
            return this.imgList.length >= this.limit
        },
    },

    watch: {
        value: {
            handler(val) {
                if (this.isFirstMount && this.value.length > 0) {
                    this.syncElUpload()
                }
            },
            deep: true,
        },
    },

    mounted() {
        if (this.value.length > 0) {
            this.syncElUpload()
        }
    },

    methods: {
    // 同步el-upload数据
        syncElUpload(val) {
            console.log(val)
            const imgList = val || this.imgList
            this.$refs.uploadRef.uploadFiles = imgList.map((v, i) => ({
                name: 'pic' + i,
                url: v,
                status: 'success',
                uid: utils.createUniqueString(),
            }))
            this.isFirstMount = false
        },
        // 上传图片之前
        beforeUpload(file) {
            this.isFirstMount = false // 将isFirstMount设置为false
            const isValidImageType = (file) => /^image\//.test(file.type) // 检查文件类型是否为图片
            if (!isValidImageType(file)) { // 如果文件类型不为图片
                Message.error('请上传图片') // 弹出错误提示
                return false // 阻止上传
            }

            if (this.useCompress) { // 如果使用了图片压缩
                return new Promise((resolve, reject) => { // 返回一个Promise对象
                    if (isValidImageType(file)) { // 判断文件是否为图片
                        lrz(file, { width: 1920 }).then((rst) => { // 使用lrz对图片进行压缩
                            file = rst.file // 获取压缩后的文件
                        }).always(() => {
                            if (utils.validImgUpload(file, this.size)) { // 验证压缩后的图片是否符合要求
                                this.isUploading = true // 设置isUploading为true
                                resolve() // 成功时resolve
                            } else {
                                reject(new Error()) // 失败时reject一个错误
                            }
                        })
                    } else {
                        reject(new Error('Not a valid image file')) // 文件不是图片，reject一个带有错误信息的Promise
                    }
                })
            }

            if (isValidImageType(file) && utils.validImgUpload(file, this.size)) { // 如果不使用压缩，直接验证文件是否为图片并符合要求
                this.isUploading = true // 设置isUploading为true
                return true // 符合要求时返回true
            }

            return false // 不是图片或不符合要求直接返回false
        },
        // 上传完单张图片
        onSuccessUpload(res, file, fileList) {
            if (res.retVal) {
                if (this.imgList.length < this.limit) {
                    this.imgList.push(res.retVal)
                }
            } else {
                this.syncElUpload()
                Message.error(res.ms)
            }
            this.isUploading = false
        },
        // 移除单张图片
        onRemoveHandler(index) {
            this.$confirm('确定删除该图片?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
            .then(() => {
                this.imgList = this.imgList.filter((v, i) => i !== index)
                const removedItem = this.imgList[index]
                this.$emit('removeImg', removedItem)
            })
            .catch(() => {})
        },
        // 超限
        onExceed() {
            this.$refs.uploadRef.abort() // 取消剩余接口请求
            this.syncElUpload()
            Message.warning(`图片超限，最多可上传${this.limit}张图片`)
        },
        onDragStart(e) {
            e.target.classList.add('hideShadow')
        },
        onDragEnd(e) {
            e.target.classList.remove('hideShadow')
        },
    },

    components: { vuedraggable },
}
</script>

<style lang="less" scoped>
/deep/ .el-upload {
  width: 100%;
  height: 100%;
}

// 上传按钮
.uploadIcon {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #c0ccda;
  background-color: #fbfdff;
  border-radius: 6px;
  font-size: 20px;
  color: #999;

  .limitTxt,
  .uploading {
    position: absolute;
    bottom: 10%;
    left: 0;
    width: 100%;
    font-size: 14px;
    text-align: center;
  }
}

// 拖拽
.vue-draggable {
  display: flex;
  flex-wrap: wrap;

  .draggable-item {
    margin-right: 5px;
    margin-bottom: 5px;
    border: 1px solid #ddd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;

    .el-image {
      width: 100%;
      height: 100%;
    }
    .shadow {
      position: absolute;
      top: 0;
      right: 0;
      background-color: rgba(0,0,0,.5);
      opacity: 0;
      transition: opacity .3s;
      color: #fff;
      font-size: 20px;
      line-height: 20px;
      padding: 2px;
      cursor: pointer;
    }
    &:hover {
      .shadow {
        opacity: 1;
      }
    }
  }
  &.hideShadow {
    .shadow {
      display: none;
    }
  }
  &.single {
    overflow: hidden;
    position: relative;

    .draggable-item {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }
  }
  &.maxHidden {
    .uploadBox {
      display: none;
    }
  }
}
</style>
