<!-- eslint-disable max-len -->
<template>
  <div class="home">
    <Layout>
      <Header class="header">
        <div class="logo">
          <img src="../assets/imgs/logo.jpg" alt="Logo" />
          <span class="site-name">不见长安</span>
        </div>
        <Menu mode="horizontal" class="main-menu">
          <Menu.Item name="home" :class="{ 'light-active': activeItem === 'home' }" @click="gotoHome">
            <Icon type="ios-home-outline"></Icon>
            <span class="nav-text">主页</span>
          </Menu.Item>
          <Menu.Item name="user" :class="{ 'light-active': activeItem === 'person' }" @click="gotoPerson">
            <Icon type="ios-person-outline"></Icon>
            <span class="nav-text">个人中心</span>
          </Menu.Item>
          <Menu.Item name="message" @click="goMessage" :class="{ 'light-active': activeItem === 'message' }">
            <Icon type="ios-chatbubbles-outline"></Icon>
            <span class="nav-text">消息</span>
          </Menu.Item>
          <Menu.Item name="about" :class="{ 'light-active': activeItem === 'about' }" @click="gotoBook">
            <Icon type="ios-bookmark-outline" />
            <span class="nav-text">文档</span>
          </Menu.Item>
          <Menu.Item @click="toggleTheme" name="theme">
            <Icon type="ios-color-palette-outline"></Icon>
            <span class="nav-text">切换主题</span>
          </Menu.Item>
          <Menu.Item v-if="isAdmin" @click="gotoAdmin" name="admin">
            <Icon type="ios-settings-outline"></Icon>
            <span class="nav-text">后台管理</span>
          </Menu.Item>
          <div class="right-menu">
            <div class="user-info">
              <viewer :url="currentUser.userHead">
                <Avatar shape="square" placement="left-start" :src="currentUser.userHead" size="large" />
              </viewer>
              <div class="name">{{ currentUser.username }}</div>
            </div>
            <Dropdown placement="bottom-start" @on-click="userOperate">
              <a href="javascript:void(0)" class="dropdown-btn">
                操作
                <Icon type="ios-arrow-down"></Icon>
              </a>
              <template #list>
                <DropdownMenu>
                  <DropdownItem name="1">个人信息</DropdownItem>
                  <DropdownItem name="2">退出登录</DropdownItem>
                </DropdownMenu>
              </template>
            </Dropdown>
          </div>
        </Menu>
      </Header>
    </Layout>

    <div class="content" style="
        background-color: #8ec5fc;
        background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
      ">
      <FloatingBall @to-top="handleToTop" @open-add-publish="openAddPublish" @to-user-home="gotoHome" />
      <AddDiary :addModal="addModal" @close-add-publish="addModal = false" @update-diary="updateDiary"></AddDiary>

      <div ref="home">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { Menu, Message, MenuItem } from "view-design";
import { loginOut, getUserNameById } from "@/api/user.js";
import AddDiary from "@/components/diary/AddDiary.vue";
import FloatingBall from "@/components/Ball.vue";
import timeUtils from "@/utils/TimeUtil.js";
import { resetTokenAndClearUser } from "../utils";

export default {
  name: "UserHome",
  data() {
    return {
      addModal: false,
      avatar:
        "https://img2.baidu.com/it/u=3790931542,4163369003&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500",
      currentUser: {},
      ws: null,
      connectionStatus: "未连接",
      messages: [],
      activeItem: "home",
    };
  },
  computed: {
    isAdmin() {
      return this.currentUser.isAdmin === 'true';
    },
  },
  components: {
    MenuItem,
    Menu,
    FloatingBall,
    AddDiary,
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
  },
  beforeDestroy() {
    this.$eventBus.$off("websocket-reconnected");
  },
  mounted() {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    switch (this.$route.path) {
      case "/userHome":
        this.gotoHome();
        break;
      case "/DiaryList":
        this.activeItem = "home";
        break;
      case "/UserProfile":
        this.activeItem = "person";
        break;
      case "/MessageList":
        this.activeItem = "message";
        break;
      case "/noteList":
        this.activeItem = "about";
        break;
      default:
        this.activeItem = "home"; // 默认激活的菜单项
        break;
    }
    this.connectWebSocket(this.currentUser.userId);
    this.$eventBus.$on("websocket-reconnected", () => {
      this.addWebsocket();
    });
  },
  methods: {
    gotoPerson() {
      this.activeItem = "person";
      this.$router.replace({ path: "/UserProfile" });
    },
    gotoBook() {
      this.activeItem = "about";
      this.$router.replace({ path: "/noteList" });
    },
    goMessage(user) {
      this.$router.replace({ path: "/MessageList" });
      this.$store.commit('setOpenMessageUserId', user);
      this.activeItem = "message";
    },
    connectWebSocket(val) {
      const url = "wss://www.bjca.xyz/websocket?userId=" + val;
      this.$store.dispatch("initializeWebSocket", url);
      this.addWebsocket();
    },
    addWebsocket() {
      const webSocketService = this.$store.state.socket;

      webSocketService.addListener("open", this.onOpen);
      webSocketService.addListener("message", this.onMessage);
      webSocketService.addListener("close", this.onClose);
    },
    onOpen() {
      this.connectionStatus = "已连接";
    },
    async getSenderName(userId) {
      const res = await getUserNameById({ userId });
      return res.retVal;
    },
    async onMessage(event) {
      if (event.data === "已收到您的消息") return;
      const msg = JSON.parse(event.data);
      if (msg.sender) {
        msg.sender = await this.getSenderName(msg.sender)
      }
      const senderName = msg.sender ? msg.sender : "系统";
      let message = {
        id: msg.messageId,
        sender: senderName,
        content: msg.content,
        type: msg.header,
        sendTime: msg.sendTime,
        header: msg.header,
      };
      this.messages.push(message);
      this.$Notice.open({
        title: "📧 新的消息",
        render: (h) => {
          const getMessageContent = (message) => {
            switch (message.header) {
              case 'txt':
                return message.content.length > 20
                  ? message.content.slice(0, 20) + "..."
                  : message.content;
              case 'img':
                return "[图片]";
              case 'video':
                return "[视频]";
              case 'audio':
                return "[音频]";
              case 'file':
                return "[文件]";
              default:
                return "未知消息类型";
            }
          };
          const getMessageIcon = (header) => {
            switch (header) {
              case 'img':
                return "🖼️";
              case 'video':
                return "🎥";
              case 'audio':
                return "🎵";
              case 'file':
                return "📁";
              default:
                return "💬"; // 默认为文本消息
            }
          };
          return h(
            "div",
            {
              style: {
                padding: "15px",
                backgroundColor: "#f9fbfd",
                border: "1px solid #e3e8ee",
                borderRadius: "10px",
                boxShadow: "0 6px 14px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                maxWidth: "300px",
              },
            },
            [
              h(
                "span",
                {
                  style: {
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "10px",
                    color: "#2c3e50", // 发送人字体颜色加深
                  },
                },
                getMessageIcon(message.header) + " 发送人：" + message.sender
              ),
              h(
                "span",
                {
                  style: {
                    marginBottom: "5px",
                    fontSize: "14px",
                    color: "#34495e", // 内容字体颜色加深
                  },
                  attrs: {
                    title: message.content, // 悬浮提示完整内容
                  },
                },
                "内容：" + getMessageContent(message)
              ),
              h(
                "span",
                {
                  style: {
                    marginBottom: "10px",
                    fontSize: "12px",
                    color: "#7f8c8d", // 发送时间使用较浅颜色
                  },
                },
                "发送时间：" + timeUtils.formatTime(message.sendTime)
              ),
              h(
                "button",
                {
                  on: {
                    click: () => {
                      this.goMessage(message.sender);
                    },
                  },
                  style: {
                    marginTop: "10px",
                    padding: "10px 16px",
                    backgroundColor: "#007bff", // 按钮颜色改为蓝色
                    color: "#fff",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "bold",
                    transition: "background-color 0.3s, transform 0.2s", // 增加按钮的过渡动画
                  },
                  on: {
                    mouseover: (e) => {
                      e.target.style.backgroundColor = "#0056b3";
                      e.target.style.transform = "scale(1.05)"; // 鼠标悬浮时放大按钮
                    },
                    mouseout: (e) => {
                      e.target.style.backgroundColor = "#007bff";
                      e.target.style.transform = "scale(1)"; // 鼠标移出时恢复原状
                    },
                  },
                },
                "查看"
              ),
            ]
          );
        },
        duration: 5,
      });


    },
    onClose() {
      console.log("连接已关闭");
      this.connectionStatus = "已关闭";
    },
    updateDiary() {
      this.$router.replace(`/SonBlank?redirect=${this.$route.fullPath}`);
    },
    openAddPublish() {
      this.addModal = true;
      this.$emit("open-add-publish", this.openAddPublish);
    },
    gotoHome() {
      this.$router.replace({ path: "/DiaryList" });
      this.activeItem = "home";
    },
    handleToTop() {
      const diaryList = this.$refs.home;
      diaryList.scrollIntoView({ behavior: "smooth", block: "start" });
      this.$emit("to-top", this.handleToTop);
    },
    userOperate(name) {
      switch (name) {
        case "1":
          break;
        case "2":
          loginOut({}).then((res) => {
            Message.success({
              content: res.retVal,
            });
          });
          resetTokenAndClearUser();
          this.$router.push({ name: "login" });
          break;
      }
    },
    logout() {
      resetTokenAndClearUser();
      this.$router.push({ name: "login" });
    },
    toggleTheme() { },
    gotoAdmin() {
      this.$router.push({ path: "/admin" });
    },
  },
};
</script>

<style scoped>
.light-active {
  color: #76c4eb;
}

.home {
  height: 100vh;
  background-color: var(--bg-color);
}

.header {
  background-color: var(--header-bg-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-shadow: var(--header-box-shadow);
  height: 60px;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 40px;
  border-radius: 50%;
}

.site-name {
  margin-left: 15px;
  font-size: 18px;
  font-weight: bold;
  color: var(--nav-text-color);
}

.main-menu {
  display: flex;
  align-items: center;
}

.right-menu {
  display: flex;
  align-items: center;
}

.user-info {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.user-info .avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-info .name {
  font-size: 16px;
  font-weight: bold;
  color: var(--user-info-color);
  padding: 5px;
}

.dropdown-btn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: var(--button-color);
}

.dropdown-btn:hover {
  background-color: var(--dropdown-btn-hover-color);
  color: var(--nav-text-hover-color);
}

.nav-text {
  padding: 0 20px;
  cursor: pointer;
  transition: color 0.3s;
  font-size: 16px;
  font-weight: bold;
  color: var(--nav-text-color);
}

.nav-text:hover {
  color: var(--nav-text-hover-color);
}

.active .nav-text {
  color: var(--nav-text-hover-color);
}

.active .nav-text.light {
  color: #000;
}

.active .nav-text.dark {
  color: #fff;
}

.dropdown-menu {
  min-width: 150px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  /* 调整下拉列表与用户名的距离 */
}

.dropdown-button {
  display: block;
  padding: 8px 12px;
  background-color: #f5f8fa;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--button-color);
}

.dropdown-button:hover {
  background-color: #e8eaf6;
  color: var(--button-hover-color);
}

/* 悬浮样式 */
.nav-text:hover,
.dropdown-btn:hover {
  background-color: var(--nav-text-hover-color);
  color: var(--text-color);
}

.active .nav-text,
.active .nav-text.light {
  background-color: var(--nav-text-hover-color);
  color: var(--text-color);
}

.dropdown-button:hover {
  background-color: var(--dropdown-btn-hover-color);
  color: var(--text-color);
}

.content {
  height: calc(100vh - 60px);
  padding: 20px;
  transition: background-color 0.3s;
  overflow-y: auto;
  /* 添加滚动条 */
}

@media screen and (max-width: 768px) {

  /* 在小屏幕上缩小内容区域的高度 */
  .content {
    height: calc(100vh - 80px);
  }
}

.footer {
  display: flex;
  /* 使用 flex 布局 */
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f5f7f9;
  /* 设置footer的背景色 */
  height: 28px;
  /* 根据设计确定footer的高度 */
  box-shadow: var(--footer-box-shadow);
  /* 如果需要底部阴影，可以添加对应的box-shadow样式 */
}

/* 添加缺失的变量 */
:root {
  --nav-text-hover-color: #000;
}
</style>
